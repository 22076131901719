// export const apiConfig = {
 
//     API_ROOT_URL: "http://10.0.1.183:8083", //UAT
//     STATIC_SESSION: "202320232023202320232023",    
//   };
   
   
  let BASE_URL;
  console.log(window.location)
   
  if (window.location.href.includes("uat")) {
    BASE_URL = 'https://qglider-api-uat.azurewebsites.net';
  } else {
    BASE_URL = 'https://qglider-api-uat.azurewebsites.net';
  }
  // else{
  //   BASE_URL = 'https://qglider-api-uat.azurewebsites.net';
  // }
   
  export default BASE_URL;